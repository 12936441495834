import React from 'react'
import { graphql } from "gatsby"
import Img from 'gatsby-image'
import BackgroundImg from 'gatsby-background-image'

import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import Layout from '../../../../../../components/layout'
import SEO from '../../../../../../components/seo'
import Heading from '../../../../../../components/heading'
import HeroBox from '../../../../../../components/herobox'
import { ButtonBase } from '@material-ui/core'
import orange from '@material-ui/core/colors/orange'
import { Alert, AlertTitle } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
    root: {

    },
    rootContainer: {
        padding: 0,
    },
    galleryContainer: {
        padding: '15px',
    },
    hero: {
        minHeight: '60vh',
        padding: '15px',
        backgroundColor: 'rgba(0,0,0,.3)',
    },
    wrapper: {
        minHeight: '60vh',
        padding: '25px',
        // backgroundColor: 'rgba(0,0,0,.3)',
        color: '#ffffff',
        borderColor: 'rgba(255,255,255,.5)',
        borderWidth: '1px',
        borderStyle: 'solid',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
    },
    // hero: {
    //     padding: '1rem',
    //     display: 'flex',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     flexDirection: 'column',
    //     minHeight: '80vh',
    //     textAlign: 'center',
    //     color: '#ffffff',
    // },
    heading1: {
        backgroundColor: 'rgba(72,189,152,.9)',
        padding: '20px;'
    },
    heading2s: {
        // color: '#48bd98',
        // marginTop: '5rem',
        '&::before': {
            content: '""',
            display: 'inline-block',
            position: 'relative',
            top: '-.1em',
            verticalAlign: 'middle',
            height: '1em',
            borderLeftColor: '#48bd98',
            borderLeftWidth: '7px',
            borderLeftStyle: 'solid',
            marginRight: '10px',
        }
    },
    paragraphs: {
        paddingTop: '30px;'
    },
    paragraph: {
        backgroundColor: 'rgba(0,0,0,.5)',
    },
    button: {
        textAlign: 'right',
        marginBottom: '1.45rem',
        paddingRight: '30px',
    },
    // Card
    cardRoot: {
        display: 'flex',

    },
    cardDetails: {
        display: 'flex',
        flexDirection: 'column',
    },
    cardContent: {
        flex: '1 0 auto',
    },
    //Grid
    gridRoot: {
        flexGrow: 1,
        marginBottom: theme.spacing(2),
    },
    gridGroup2: {
        padding: '0 30px',
    },
    gridPaper: {
        padding: theme.spacing(2),
        // margin: 'auto',
        maxWidth: 600,
    },
    gridPaper2: {
        padding: theme.spacing(2),
        // margin: 'auto',
        maxWidth: 600,
    },
    gridImage: {
        width: 250,
        // height: 150,
    },
    gridImage2: {
        width: 350,
        // height: 150,
    },
    gridImg: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    gridImg2: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    //Stepper
    stepperRoot: {
        width: '100%',
    },
    stepperButton: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        backgroundColor: '#48bd96',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#30a679',
        }
    },
    stepperButtonTxt: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
        marginBottom: '2rem',
    },
    stLabel: {
        padding: '1px',
        backgroundColor: orange[600],
        color: '#ffffff',
        borderRadius: '2px',
    },
    //Mobile Stepper
    msRoot: {
        maxWidth: 500,
        flexGrow: 1,
    },
    msHeader: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        // paddingLeft: theme.spacing(4),
        // backgroundColor: theme.palette.background.default,
    },
    msBody: {
        // display: 'flex',
        // alignItems: 'center',
        padding: '15px'
    },
    msImg: {
        // height: 255,
        maxWidth: 1000,
        overflow: 'hidden',
        display: 'block',
        width: '100%',
        marginBottom: '0',
    },
    msGridItem: {
        marginTop: '5rem',
    },
    msButton: {
        marginRight: theme.spacing(1),
        backgroundColor: '#48bd96',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#30a679',
        },
    },
    // Alert
    alertContainer: {
        // width: '100%',
        marginTop: '2rem',
        '& > * + *': {
            marginTop: theme.spacing(2),
        }
    },
    // Img
    imageContainer: {
        margin: '15px',
        '& > *': {
            borderRadius: '3px',
        }
    },
}));

function getSteps() {
    return [
        '从触控式面板选择想喝的葡萄酒和份量 ',
        '放入现金',
        '从触控式面板左边的篮子取出酒杯 ',
        '长按您选择的葡萄酒按键注酒 '
    ];
};

const IsawaonsenStationWineServerPage = ({ data }) => {
    const classes = useStyles();
    const language = 'zh_hans';
    const slug = 'station/isawaonsen/wine/wine-server';
    const pageTitle = '车站葡萄酒机';
    const sectionTitle1 = '简单明了！葡萄酒机的使用方法';
    const sectionTitle2 = '';
    const sectionTitle3 = '';
    const notChild = false;
    const child = true;

    /** Stepper */
    function getStepContent(step) {
        switch (step) {
            case 0:
                return (
                    <div className={classes.gridGroup}>
                        <div className={classes.gridRoot}>
                            <Paper className={classes.gridPaper}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <ButtonBase className={classes.gridImage}>
                                            <img className={classes.gridImg} src={data.step11.childImageSharp.fluid.src} />
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Grid item xs>
                                                <Typography gutterButton variant="subtitle1">
                                                    &nbsp;
                                                </Typography>
                                                <Typography gutterButton variant="body2">
                                                    选择您中意的红酒、白酒或玫瑰红酒
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                    
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1"><span className={classes.stLabel}>1-1</span></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                        <div className={classes.gridRoot}>
                            <Paper className={classes.gridPaper}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <ButtonBase className={classes.gridImage}>
                                            <img className={classes.gridImg} src={data.step12.childImageSharp.fluid.src} />
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Grid item xs>
                                                <Typography gutterButton variant="subtitle1">
                                                    &nbsp;
                                                </Typography>
                                                <Typography gutterButton variant="body2">
                                                    决定喝那一种之后选择您想喝的份量
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                    
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1"><span className={classes.stLabel}>1-2</span></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                        <div className={classes.gridRoot}>
                            <Paper className={classes.gridPaper}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <ButtonBase className={classes.gridImage}>
                                            <img className={classes.gridImg} src={data.step13.childImageSharp.fluid.src} />
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Grid item xs>
                                                <Typography gutterButton variant="subtitle1">
                                                    &nbsp;
                                                </Typography>
                                                <Typography gutterButton variant="body2">
                                                    记住您选择的葡萄酒编号
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                    
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1"><span className={classes.stLabel}>1-3</span></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div className={classes.gridGroup}>
                        <div className={classes.gridRoot}>
                            <Paper className={classes.gridPaper}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <ButtonBase className={classes.gridImage}>
                                            <img className={classes.gridImg} src={data.step21.childImageSharp.fluid.src} />
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Grid item xs>
                                                <Typography gutterButton variant="subtitle1">
                                                    &nbsp;
                                                </Typography>
                                                <Typography gutterButton variant="body2">
                                                    确认您选择的葡萄酒、份量和金额是否正确
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                    
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1"><span className={classes.stLabel}>2-1</span></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                        <div className={classes.gridRoot}>
                            <Paper className={classes.gridPaper}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <ButtonBase className={classes.gridImage}>
                                            <img className={classes.gridImg} src={data.step22.childImageSharp.fluid.src} />
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Grid item xs>
                                                <Typography gutterButton variant="subtitle1">
                                                    &nbsp;
                                                </Typography>
                                                <Typography gutterButton variant="body2">
                                                    确定之后再放入现金
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                    
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1"><span className={classes.stLabel}>2-2</span></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className={classes.gridGroup}>
                        <div className={classes.gridRoot}>
                            <Paper className={classes.gridPaper}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <ButtonBase className={classes.gridImage}>
                                            <img className={classes.gridImg} src={data.step31.childImageSharp.fluid.src} />
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Grid item xs>
                                                <Typography gutterButton variant="subtitle1">
                                                    &nbsp;
                                                </Typography>
                                                <Typography gutterButton variant="body2">
                                                    面板左边有个放酒杯的篮子
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                    
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1"><span className={classes.stLabel}>3-1</span></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                        <div className={classes.gridRoot}>
                            <Paper className={classes.gridPaper}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <ButtonBase className={classes.gridImage}>
                                            <img className={classes.gridImg} src={data.step32.childImageSharp.fluid.src} />
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Grid item xs>
                                                <Typography gutterButton variant="subtitle1">
                                                    &nbsp;
                                                </Typography>
                                                <Typography gutterButton variant="body2">
                                                    酒杯为塑胶制品，喝完可丢弃
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                    
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1"><span className={classes.stLabel}>3-2</span></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                        <div className={classes.gridRoot}>
                            <Paper className={classes.gridPaper}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <ButtonBase className={classes.gridImage}>
                                            <img className={classes.gridImg} src={data.step33.childImageSharp.fluid.src} />
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Grid item xs>
                                                <Typography gutterButton variant="subtitle1">
                                                    &nbsp;
                                                </Typography>
                                                <Typography gutterButton variant="body2">
                                                    取出酒杯
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                    
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1"><span className={classes.stLabel}>3-3</span></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div className={classes.gridGroup}>
                        <div className={classes.gridRoot}>
                            <Paper className={classes.gridPaper}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <ButtonBase className={classes.gridImage}>
                                            <img className={classes.gridImg} src={data.step41.childImageSharp.fluid.src} />
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Grid item xs>
                                                <Typography gutterButton variant="subtitle1">
                                                    &nbsp;
                                                </Typography>
                                                <Typography gutterButton variant="body2">
                                                    走到您选择的葡萄酒编号前面
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                    
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1"><span className={classes.stLabel}>4-1</span></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                        <div className={classes.gridRoot}>
                            <Paper className={classes.gridPaper}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <ButtonBase className={classes.gridImage}>
                                            <img className={classes.gridImg} src={data.step42.childImageSharp.fluid.src} />
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Grid item xs>
                                                <Typography gutterButton variant="subtitle1">
                                                    &nbsp;
                                                </Typography>
                                                <Typography gutterButton variant="body2">
                                                    将酒杯放到该编号的注酒口下方
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                    
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1"><span className={classes.stLabel}>4-2</span></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                        <div className={classes.gridRoot}>
                            <Paper className={classes.gridPaper}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <ButtonBase className={classes.gridImage}>
                                            <img className={classes.gridImg} src={data.step43.childImageSharp.fluid.src} />
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Grid item xs>
                                                <Typography gutterButton variant="subtitle1">
                                                    &nbsp;
                                                </Typography>
                                                <Typography gutterButton variant="body2">
                                                    长按您选择的份量按键，直到葡萄酒注出为止
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                    
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1">4-3</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                        <div className={classes.gridRoot}>
                            <Paper className={classes.gridPaper}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <ButtonBase className={classes.gridImage}>
                                            <img className={classes.gridImg} src={data.step44.childImageSharp.fluid.src} />
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Grid item xs>
                                                <Typography gutterButton variant="subtitle1">
                                                    &nbsp;
                                                </Typography>
                                                <Typography gutterButton variant="body2">
                                                    在地的美味葡萄酒，请慢用
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                    
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1"><span className={classes.stLabel}>4-4</span></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                    </div>
                );
            default:
                return null;
        };
    }
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };
    const handleReset = () => {
        setActiveStep(0);
    };
    const stationName = '全球独一无二的车站葡萄酒机';
    const descriptions = [
        '16种当地葡萄酒厂的经典名作',
        '200日圆起就可以品嚐到多种在地美味'
    ];

    /** MobileStepper */
    const slides1 = [
        {
            label: '',
            imgPath: data.Q1.childImageSharp.fluid.src,
        },
        {
            label: '',
            imgPath: data.Q2.childImageSharp.fluid.src,
        }
    ];
    const maxSlides1 = slides1.length;
    const [activeSlides1, setActiveSlides1] = React.useState(0);
    const handleNextSlides1 = () => {
        setActiveSlides1(prevActiveSlides1 => prevActiveSlides1 + 1);
    }
    const handleBackSlides1 = () => {
        setActiveSlides1(prevActiveSlides1 => prevActiveSlides1 -1);
    }
    const slides2 = [
        {
            label: '',
            imgPath: data.L1.childImageSharp.fluid.src,
        },
        {
            label: '',
            imgPath: data.L2.childImageSharp.fluid.src,
        }
    ];
    const maxSlides2 = slides2.length;
    const [activeSlides2, setActiveSlides2] = React.useState(0);
    const handleNextSlides2 = () => {
        setActiveSlides2(prevActiveSlides2 => prevActiveSlides2 + 1);
    }
    const handleBackSlides2 = () => {
        setActiveSlides2(prevActiveSlides2 => prevActiveSlides2 -1);
    }

    const image = data.hero.childImageSharp.fluid;
    return (
        <Layout language={language} slug={slug}>
            <SEO title={pageTitle} />
            <BackgroundImg
                Tag="section"
                fluid={image}
                backgourndColor={`#48bd98`}
                // style={{
                //     minHeight: '60vh',
                //     backgroundSize: 'auto',
                // }}
            >
                <section className={classes.hero}><div className={classes.wrapper}>
                    <h3><span>全球独一无二的!</span></h3>
                    {/* <h2><span className={classes.heading2}>駅なかワインサーバー</span></h2> */}
                    <Typography className={classes.heading2} variant="h2" content="h2">车站葡萄酒机</Typography>
                    <div className={classes.paragraphs}>
                        {
                            descriptions.map((description, i) => (
                                <p className={classes.paragraph} key={i}><span className={classes.span}>{description}</span></p>
                            ))
                        }
                    </div>
                </div></section>
            </BackgroundImg>
            <Container component="section" className={classes.rootContainer}>
                <Box component="article">
                    <section>
                        <Heading title="葡萄酒机怎麽去" isChild={notChild} />
                        <div className={classes.gridGroup2}>
                            <div className={classes.gridRoot}>
                                <Paper className={classes.gridPaper2}>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <ButtonBase className={classes.gridImage2}>
                                                <img className={classes.gridImg2} src={data.D1.childImageSharp.fluid.src} />
                                            </ButtonBase>
                                        </Grid>
                                        <Grid item xs={12} sm container>
                                            <Grid item xs container direction="column" spacing={2}>
                                                <Grid item xs>
                                                    <Typography gutterButton variant="subtitle1">
                                                        &nbsp;
                                                    </Typography>
                                                    <Typography gutterButton variant="body2">
                                                        出检票口后右转
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                        
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1"><span className={classes.stLabel}>1</span></Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </div>
                            <div className={classes.gridRoot}>
                                <Paper className={classes.gridPaper2}>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <ButtonBase className={classes.gridImage2}>
                                                <img className={classes.gridImg2} src={data.D2.childImageSharp.fluid.src} />
                                            </ButtonBase>
                                        </Grid>
                                        <Grid item xs={12} sm container>
                                            <Grid item xs container direction="column" spacing={2}>
                                                <Grid item xs>
                                                    <Typography gutterButton variant="subtitle1">
                                                        &nbsp;
                                                    </Typography>
                                                    <Typography gutterButton variant="body2">
                                                        从左手边的电动扶梯下楼
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                        
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1"><span className={classes.stLabel}>2</span></Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </div>
                            <div className={classes.gridRoot}>
                                <Paper className={classes.gridPaper2}>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <ButtonBase className={classes.gridImage2}>
                                                <img className={classes.gridImg2} src={data.D3.childImageSharp.fluid.src} />
                                            </ButtonBase>
                                        </Grid>
                                        <Grid item xs={12} sm container>
                                            <Grid item xs container direction="column" spacing={2}>
                                                <Grid item xs>
                                                    <Typography gutterButton variant="subtitle1">
                                                        &nbsp;
                                                    </Typography>
                                                    <Typography gutterButton variant="body2">
                                                        下到一楼后180度右转
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                        
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1"><span className={classes.stLabel}>3</span></Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </div>
                            <div className={classes.gridRoot}>
                                <Paper className={classes.gridPaper2}>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <ButtonBase className={classes.gridImage2}>
                                                <img className={classes.gridImg2} src={data.D4.childImageSharp.fluid.src} />
                                            </ButtonBase>
                                        </Grid>
                                        <Grid item xs={12} sm container>
                                            <Grid item xs container direction="column" spacing={2}>
                                                <Grid item xs>
                                                    <Typography gutterButton variant="subtitle1">
                                                        &nbsp;
                                                    </Typography>
                                                    <Typography gutterButton variant="body2">
                                                        走进观光服务处内
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                        
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1"><span className={classes.stLabel}>4</span></Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </div>
                            <div className={classes.gridRoot}>
                                <Paper className={classes.gridPaper2}>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <ButtonBase className={classes.gridImage2}>
                                                <img className={classes.gridImg2} src={data.D5.childImageSharp.fluid.src} />
                                            </ButtonBase>
                                        </Grid>
                                        <Grid item xs={12} sm container>
                                            <Grid item xs container direction="column" spacing={2}>
                                                <Grid item xs>
                                                    <Typography gutterButton variant="subtitle1">
                                                        &nbsp;
                                                    </Typography>
                                                    <Typography gutterButton variant="body2">
                                                        左边就是葡萄酒机
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                        
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1"><span className={classes.stLabel}>5</span></Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </div>
                        </div>
                    </section>
                    <section>
                        <Heading title={sectionTitle1} isChild={notChild} />
                        <div className={classes.imageContainer}><Img objectFit="contain" fluid={data.wineserver.childImageSharp.fluid} /></div>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((label, index) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                    <StepContent>
                                        <Typography>{getStepContent(index)}</Typography>
                                        <div className={classes.actionContainer}>
                                            <div>
                                                <Button
                                                    disabled={activeStep === 0}
                                                    onClick={handleBack}
                                                    className={classes.stepperButtonTxt}
                                                >
                                                    Back
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    onClick={handleNext}
                                                    className={classes.stepperButton}
                                                >
                                                    {activeStep === steps.length -1 ? 'Finish' : 'Next'}
                                                </Button>
                                            </div>
                                        </div>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === steps.length && (
                            <Paper square elevation={0} className={classes.restContainer}>
                                <Typography></Typography>
                                <Button onClick={handleReset} className={classes.stepperButton}>
                                    Restart
                                </Button>
                            </Paper>
                        )}
                        <div className={classes.alertContainer}>
                            <Alert severity="info">
                                这些葡萄酒在2楼的New Days 都有卖，<br />喝到喜欢的可以去买哦！
                            </Alert>
                        </div>
                    </section>
                    <section>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} className={classes.msGridItem}>
                                <div clasName={classes.msRoot}>
                                    <Paper square elevation={0} className={classes.msHeader}>
                                        <Typography><h2><span className={classes.heading2s}>不拿可惜！</span></h2></Typography>
                                    </Paper>
                                    <div className={classes.msBody}>
                                        <p>到服务柜台填写问卷，就可以得到一条非常有纪念价值的战国绘卷手巾！  </p>
                                        <p>问卷为英文，将写好的问卷交给柜台人员，</p>
                                        <p>他们就会把这条漂亮的战国绘卷手巾送给您！</p>
                                    </div>
                                    <img 
                                        className={classes.msImg}
                                        src={slides1[activeSlides1].imgPath}
                                        alt={slides1[activeSlides1].label}
                                    />
                                    <MobileStepper
                                        steps={maxSlides1}
                                        position="static"
                                        variant="dots"
                                        activeStep={activeSlides1}
                                        nextButton={
                                            <Button size="small" onClick={handleNextSlides1} disabled={activeSlides1 === maxSlides1 - 1}>
                                                Next
                                                <KeyboardArrowRight />
                                            </Button>
                                        }
                                        backButton={
                                            <Button size="small" onClick={handleBackSlides1} disabled={activeSlides1 === 0}>
                                                <KeyboardArrowLeft />
                                                Back
                                            </Button>
                                        }
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.msGridItem}>
                                <div clasName={classes.msRoot}>
                                    <Paper square elevation={0} className={classes.msHeader}>
                                        <Typography><h2><span className={classes.heading2s}>笛吹不但是日本第一的葡萄产地，</span></h2></Typography>
                                    </Paper>
                                    <div className={classes.msBody}>
                                        <p>在国际上也是极负盛名的葡萄酒乡，</p>
                                        <p>品牌与种类都非常丰富。</p>
                                        <Button
                                            href="/station/isawaonsen/wine/winery/#buyWine"
                                            variant="contained"
                                            endIcon={<KeyboardArrowRight />}
                                            className={classes.msButton}
                                        >买葡萄酒</Button>
                                        <Button
                                            href="/station/isawaonsen/wine/winery/#wineryInfo"
                                            variant="contained"
                                            endIcon={<KeyboardArrowRight />}
                                            className={classes.msButton}
                                        >观酒厂</Button>
                                    </div>
                                    <img 
                                        className={classes.msImg}
                                        src={slides2[activeSlides2].imgPath}
                                        alt={slides2[activeSlides2].label}
                                    />
                                    <MobileStepper
                                        steps={maxSlides2}
                                        position="static"
                                        variant="dots"
                                        activeStep={activeSlides2}
                                        nextButton={
                                            <Button size="small" onClick={handleNextSlides2} disabled={activeSlides2 === maxSlides1 - 1}>
                                                Next
                                                <KeyboardArrowRight />
                                            </Button>
                                        }
                                        backButton={
                                            <Button size="small" onClick={handleBackSlides2} disabled={activeSlides2 === 0}>
                                                <KeyboardArrowLeft />
                                                Back
                                            </Button>
                                        }
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </section>
                </Box>
            </Container>
            {/* <pre>{JSON.stringify(data, null, 4)}</pre> */}
        </Layout>
    )
};

export const query = graphql`
  {
    hero:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/hero.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wineserver:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/wine-server.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D0:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/direction/0.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D1:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/direction/1.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D2:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/direction/2.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D3:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/direction/3.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D4:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/direction/4.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D5:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/direction/5.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step11:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/instructions/step1/wine-server-touchpanel-1.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step12:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/instructions/step1/wine-server-touchpanel-2.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step13:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/instructions/step1/wine-server-touchpanel-3.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step21:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/instructions/step2/wine-server-payment-1.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step22:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/instructions/step2/wine-server-payment-2.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step31:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/instructions/step3/wine-server-glass-1.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step32:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/instructions/step3/wine-server-glass-2.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step33:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/instructions/step3/wine-server-glass-3.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step41:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/instructions/step4/wine-server-pour-1.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step42:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/instructions/step4/wine-server-pour-2.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step43:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/instructions/step4/wine-server-pour-3.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step44:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/instructions/step4/wine-server-pour-4.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Q1:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/questionnaire/hand-towel.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Q2:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/questionnaire/questionnaire.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    L1:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/link/grapes.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    L2:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/link/wine-glasses.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IsawaonsenStationWineServerPage